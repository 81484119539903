<template>

  <div class="position-relative">
    <div class="progress">
      <div class="progress-bar" role="progressbar" style="width: 5%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <div class="bottom-navigation">

      <button class="prev btn"  @click="$refs.fullpage.api.moveSectionUp()">
        Prev
      </button>
      <button class="next btn" @click="$refs.fullpage.api.moveSectionDown()">
        Next
      </button>
    </div>

    <img alt="BA" src="./assets/logo.png" class="top-logo">
    <full-page ref="fullpage" :options="options" id="fullpage">
      <form class="questionnaire">
      <div class="section">
        <div class="center--section">
          <div class="form-group">
            <label class="title">What is your company name?</label>
            <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
          </div>
        </div>
      </div>
      <div class="section">
        <div class="center--section">
          <div class="form-group">
            <label class="title">What is your affiliate project called?</label>
            <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
          </div>
        </div>
      </div>
      <div class="section">
        <div class="center--section">
          <div class="form-group">
            <label class="title">Please provide your business address</label>
            <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
          </div>
        </div>
      </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">What is your email address?</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Please provide your phone number</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">What is your Skype ID?</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Do you have an existing affiliate program?</label>
              <div >
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                  <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                  <label class="form-check-label" for="inlineCheckbox2">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Which affiliate software do you currently use?</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">What is the net gaming revenue of your affiliate program?</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Have you ever changed your affiliate software?</label>
              <div >
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                  <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                  <label class="form-check-label" for="inlineCheckbox2">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Do you have a database to migrate to the new affiliate platform?</label>
              <div >
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                  <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                  <label class="form-check-label" for="inlineCheckbox2">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">How many affiliates do you have in your system?</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">How many players/users are in your affiliate system?</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Please give us the brands and (brand's) products you plan to offer on your affiliate platform.</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">What currency do you use for reporting?</label>
              <div >
                <div class="form-check text-left form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                  <label class="form-check-label" for="inlineCheckbox1">EUR</label>
                </div>
                <div class="form-check text-left form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                  <label class="form-check-label" for="inlineCheckbox2">USD</label>
                </div>
                <div class="form-check text-left  form-check-inline">
                  <input class="form-check-input " type="checkbox" id="inlineCheckbox2" value="option2">
                  <label class="form-check-label" for="inlineCheckbox2">Other</label>
                </div>
                <div>
                  <input type="text" class="form-control inp-adjust" placeholder="Type your answer here.." aria-label="Company name">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Which currency would you like to use on statements?</label>
              <div >
                <div class="form-check text-left form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                  <label class="form-check-label" for="inlineCheckbox1">EUR</label>
                </div>
                <div class="form-check text-left form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                  <label class="form-check-label" for="inlineCheckbox2">USD</label>
                </div>
                <div class="form-check text-left form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                  <label class="form-check-label" for="inlineCheckbox2">Other</label>

                </div>
                <div>
                  <input type="text" class="form-control  inp-adjust" placeholder="Type your answer here.." aria-label="Company name">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Please give us the brands and (brand's) products you plan to offer on your affiliate platform.</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Do you have any special tracking requirements?</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Do you have any special reporting needs?</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Which commission structure would you like to offer to your affiliates?</label>
              <div >
                <div class="form-check text-left form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                  <label class="form-check-label" for="inlineCheckbox1">CPA</label>
                </div>
                <div class="form-check text-left form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                  <label class="form-check-label" for="inlineCheckbox2">Revenue Share</label>
                </div>
                <div class="form-check text-left form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                  <label class="form-check-label" for="inlineCheckbox2">Hybrid</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Do you need additional languages aside from English for your affiliate interface?</label>
              <div >
                <div class="form-check text-left form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                  <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                </div>
                <div class="form-check text-left form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                  <label class="form-check-label" for="inlineCheckbox2">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">If yes, which language?</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Which domain should we use for your affiliate platform?</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">What is your email for support to your affiliates?</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Which support channels would you like to offer to your affiliates?</label>
              <div >
                <div class="form-check text-left form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                  <label class="form-check-label" for="inlineCheckbox1">Skype</label>
                </div>
                <div class="form-check text-left form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                  <label class="form-check-label" for="inlineCheckbox2">Telegram</label>
                </div>
                <div class="form-check text-left form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                  <label class="form-check-label" for="inlineCheckbox2">WhatsApp</label>
                </div>
                <div class="form-check text-left form-check-inline">
                  <input class="form-check-input " type="checkbox" id="inlineCheckbox2" value="option2">
                  <label class="form-check-label" for="inlineCheckbox2">Other</label>
                </div>
                <div>
                  <input type="text" class="form-control inp-adjust" placeholder="Type your answer here.." aria-label="Company name">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">How many admin accounts do you need?</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Which payment methods should be set up for affiliates to withdraw commission?</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Did you request additional development or any special feature?</label>
              <div >
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                  <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                  <label class="form-check-label" for="inlineCheckbox2">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">If yes, please list your requests.</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Please provide URL to Terms and Conditions/Terms of Service</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Please provide URL to Privacy Policy</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Please provide us with your logo in vector or PNG format</label>
              <div class="file">
                <input type="file" id="file" class="upload-control" aria-label="File browser example">
                <span class="file-custom"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Is there anything our onboarding team should be aware of?</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">When is your team available for a weekly update? (DAY of the WEEK, hour)</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">When would you like to launch your affiliate network?</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
        <div class="section">
          <div class="center--section">
            <div class="form-group">
              <label class="title">Please provide us with your main developer (in charge of the affiliate program setup) email address.</label>
              <input type="text" class="form-control" placeholder="Type your answer here.." aria-label="Company name">
            </div>
          </div>
        </div>
      </form>
    </full-page>

  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      options: {
        menu: "#menu",
        anchors: ["page1", "page2", "page3", "page4", "page5", "page6", "page7", "page8", "page9", "page10", "page11", "page12", "page13", "page14", "page15", "page16", "page17", "page18", "page19", "page20", "page21", "page22", "page23", "page24", "page25", "page26", "page27", "page28", "page29", "page30", "page31", "page32", "page33", "page34", "page35"],
        sectionsColor: ["#000", "#000", "#000"]
      }
    };
  }
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}

</style>
<style lang="stylus">
@import "./assets/css/global.styl"




</style>

