import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import VueStepWizard from 'vue-step-wizard'
import 'vue-step-wizard/dist/vue-step-wizard.css'
import $ from 'jquery'
import './plugins/fullpage.js'
Vue.use(VueStepWizard);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

