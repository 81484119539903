<template>
<div class="homePage position-relative ">
    <!--
  <img alt="Stars" src="../assets/wesee_login_left.png" class="stars left">
  <img alt="Stars" src="../assets/wesee_login_right.png" class="stars right">-->
    <div class="text-center">
      <img alt="Stars" src="../assets/logo.png" class="top-logo">
    </div>
    <h1>Welcome to Backend Architects!</h1>
    <div class="container">
        <div class="row">
            <div class="col-12">
              <form class="questionnaire">
                <form-wizard>
                  <tab-content title="" :selected="true">
                      <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                        <label class="title">What is your company name?</label>
                        <input type="text" class="form-control" placeholder="" aria-label="Company name">
                      </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">What is your affiliate project called?</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">Please provide your business address</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">What is your email address?</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">Please provide your phone number</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">What is your Skype ID?</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <!--
                      <div class="mb-4 d-flex justify-content-between">
                        <label class="title">Do you have a live website</label>
                        <div>
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                            <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                          </div>
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                            <label class="form-check-label" for="inlineCheckbox2">No</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-4 d-flex justify-content-between align-items-center">
                        <label class="title text-left">Do you wish WePlay to provide you with a <br> standardised website with your logo?</label>
                        <div >
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                            <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                          </div>
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                            <label class="form-check-label" for="inlineCheckbox2">No</label>
                          </div>
                        </div>

                      </div>
                      <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust-dd align-items-center">
                        <label class="title">Primary contact</label>
                        <div class="input-group dd-input">
                          <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" ><i class="fal fa-envelope"></i></button>
                          <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#"><i class="fal fa-envelope"></i> Email</a></li>
                            <li><a class="dropdown-item" href="#"><i class="fab fa-telegram-plane"></i> Telegram</a></li>
                            <li><a class="dropdown-item" href="#"><i class="fab fa-whatsapp"></i> Whatsapp</a></li>
                          </ul>
                          <input type="text" class="form-control" placeholder="" aria-label="Text input with dropdown button">
                        </div>
                      </div>
                      <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust-dd align-items-center">
                        <label class="title">Tech representative contact</label>
                        <div class="input-group dd-input">
                          <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" ><i class="fal fa-envelope"></i></button>
                          <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#"><i class="fal fa-envelope"></i> Email</a></li>
                            <li><a class="dropdown-item" href="#"><i class="fab fa-telegram-plane"></i> Telegram</a></li>
                            <li><a class="dropdown-item" href="#"><i class="fab fa-whatsapp"></i> Whatsapp</a></li>
                          </ul>
                          <input type="text" class="form-control" placeholder="" aria-label="Text input with dropdown button">
                        </div>

                      </div>

                      <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                        <label class="title">Customer support mail</label>
                        <input type="text" class="form-control" placeholder="" aria-label="Brand Full Name">
                      </div>
                      -->
                  </tab-content>
                  <tab-content title="">
                    <div class="mb-4 d-flex justify-content-between">
                      <label class="title">Do you have an existing affiliate program? </label>
                      <div >
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                          <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                          <label class="form-check-label" for="inlineCheckbox2">No</label>
                        </div>
                      </div>

                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">Which affiliate software do you currently use?</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">What is the net gaming revenue of your affiliate program?</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between">
                      <label class="title">Have you ever changed your affiliate software?</label>
                      <div >
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                          <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                          <label class="form-check-label" for="inlineCheckbox2">No</label>
                        </div>
                      </div>

                    </div>
                    <div class="mb-4 d-flex justify-content-between">
                      <label class="title text-left">Do you have a database to migrate to the new affiliate platform?</label>
                      <div >
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                          <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                          <label class="form-check-label" for="inlineCheckbox2">No</label>
                        </div>
                      </div>

                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">How many affiliates do you have in your system?</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">How many players/users are in your affiliate system?</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
<!--

                      <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                        <label class="title">Brand Logo (Upload)</label>
                        <div class="file">
                          <input type="file" id="file" class="upload-control" aria-label="File browser example">
                          <span class="file-custom"></span>
                        </div>
                      </div>

                      <div class="mb-4 d-flex justify-content-between">
                        <label class="title">Poker supported platforms:</label>
                        <div class="text-left">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                            <label class="form-check-label" for="inlineCheckbox1">HTML5 (mobile & desktop)</label>
                          </div>
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                            <label class="form-check-label" for="inlineCheckbox2">iOS</label>
                          </div>
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3">
                            <label class="form-check-label" for="inlineCheckbox3">Android</label>
                          </div>
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox4" value="option4">
                            <label class="form-check-label" for="inlineCheckbox4">Windows</label>
                          </div>
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox5" value="option5">
                            <label class="form-check-label" for="inlineCheckbox5">MacOS</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-4 d-flex justify-content-between">
                        <label class="title">Casino within poker client </label>
                        <div >
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                            <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                          </div>
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                            <label class="form-check-label" for="inlineCheckbox2">No</label>
                          </div>
                        </div>

                      </div>

                      <div class="mb-4 d-flex justify-content-between">
                        <label class="title">Sportsbook within poker client  </label>
                        <div >
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                            <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                          </div>
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                            <label class="form-check-label" for="inlineCheckbox2">No</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                        <label class="title">Software Language</label>
                        <input type="text" class="form-control" placeholder="" aria-label="Brand Full Name">
                      </div>-->
                  </tab-content>
                  <tab-content title="">
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">Please give us the brands and (brand's) products you plan to offer on your affiliate platform.</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between">
                      <label class="title">What currency do you use for reporting?</label>
                      <div >
                        <div class="form-check text-left form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                          <label class="form-check-label" for="inlineCheckbox1">EUR</label>
                        </div>
                        <div class="form-check text-left form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                          <label class="form-check-label" for="inlineCheckbox2">USD</label>
                        </div>
                        <div class="form-check text-left  form-check-inline">
                          <input class="form-check-input " type="checkbox" id="inlineCheckbox2" value="option2">
                          <label class="form-check-label" for="inlineCheckbox2">Other</label>

                        </div>
                        <div>
                          <input type="text" class="form-control inp-adjust" placeholder="" aria-label="Company name">
                        </div>
                      </div>
                    </div>
                    <div class="mb-4 d-flex justify-content-between">
                      <label class="title">Which currency would you like to use on statements?</label>
                      <div >
                        <div class="form-check text-left form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                          <label class="form-check-label" for="inlineCheckbox1">EUR</label>
                        </div>
                        <div class="form-check text-left form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                          <label class="form-check-label" for="inlineCheckbox2">USD</label>
                        </div>
                        <div class="form-check text-left form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                          <label class="form-check-label" for="inlineCheckbox2">Other</label>

                        </div>
                        <div>
                          <input type="text" class="form-control  inp-adjust" placeholder="" aria-label="Company name">
                        </div>
                      </div>
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">Do you have any special tracking requirements?</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">Do you have any special reporting needs?</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between">
                      <label class="title text-left">Which commission structure would you<br> like to offer to your affiliates?</label>
                      <div >
                        <div class="form-check text-left form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                          <label class="form-check-label" for="inlineCheckbox1">CPA</label>
                        </div>
                        <div class="form-check text-left form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                          <label class="form-check-label" for="inlineCheckbox2">Revenue Share</label>
                        </div>
                        <div class="form-check text-left form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                          <label class="form-check-label" for="inlineCheckbox2">Hybrid</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-4 d-flex justify-content-between">
                      <label class="title text-left">Do you need additional languages aside from English for your affiliate interface?
                      </label>
                      <div >
                        <div class="form-check text-left form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                          <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                        </div>
                        <div class="form-check text-left form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                          <label class="form-check-label" for="inlineCheckbox2">No</label>
                        </div>

                      </div>
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">If yes, which language?</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">Which domain should we use for your affiliate platform?</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">What is your email for support to your affiliates?</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between">
                      <label class="title text-left">Which support channels would you like to offer to your affiliates?</label>
                      <div >
                        <div class="form-check text-left form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                          <label class="form-check-label" for="inlineCheckbox1">Skype</label>
                        </div>
                        <div class="form-check text-left form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                          <label class="form-check-label" for="inlineCheckbox2">Telegram</label>
                        </div>
                        <div class="form-check text-left form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                          <label class="form-check-label" for="inlineCheckbox2">WhatsApp</label>
                        </div>
                        <div class="form-check text-left form-check-inline">
                          <input class="form-check-input " type="checkbox" id="inlineCheckbox2" value="option2">
                          <label class="form-check-label" for="inlineCheckbox2">Other</label>
                        </div>
                        <div>
                          <input type="text" class="form-control inp-adjust" placeholder="" aria-label="Company name">
                        </div>
                      </div>
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">How many admin accounts do you need?</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">Which payment methods should be set up for affiliates to withdraw commission?</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between">
                      <label class="title text-left">Did you request additional development or any special feature?</label>
                      <div >
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                          <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                          <label class="form-check-label" for="inlineCheckbox2">No</label>
                        </div>
                      </div>

                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">If yes, please list your requests.</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">Please provide URL to Terms and Conditions/Terms of Service</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">Please provide URL to Privacy Policy</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">Please provide us with your logo in vector or PNG format</label>
                      <div class="file">
                        <input type="file" id="file" class="upload-control" aria-label="File browser example">
                        <span class="file-custom"></span>
                      </div>
                    </div>
                    <!--
                      <div class="mb-4">
                        <label class="title text-left">Payment methods (if yes 5.1.) - Domain ownership will have to be migrated notification (need more contacts, ask here)
                        </label>
                        <div class="d-flex justify-content-around">
                          <div>
                            <label class="title sub">
                              Deposits 5. 1
                            </label>
                            <div class="form-check text-left ">
                              <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                              <label class="form-check-label" for="inlineCheckbox1">Credit Card</label>
                            </div>
                            <div class="form-check text-left ">
                              <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                              <label class="form-check-label" for="inlineCheckbox2">Aircash</label>
                            </div>
                            <div class="form-check text-left">
                              <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option3">
                              <label class="form-check-label" for="inlineCheckbox3">Crypto</label>
                            </div>
                          </div>
                          <div>
                            <label class="title sub">
                              Withdrawals 5.2.
                            </label>
                            <div class="form-check text-left">
                              <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                              <label class="form-check-label" for="inlineCheckbox1">Manual bank withdrawal</label>
                            </div>
                            <div class="form-check text-left">
                              <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option3">
                              <label class="form-check-label" for="inlineCheckbox3">Crypto</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-4  d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                        <label class="title">Request for additional methods </label>
                        <input type="text" class="form-control" placeholder="" aria-label="Brand Full Name">
                      </div>
                      <div class="mb-4 d-flex justify-content-between">
                        <label class="title text-left">Will you have your own marketing<br> promotions (if yes 11.1.) </label>
                        <div >
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                            <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                          </div>
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                            <label class="form-check-label" for="inlineCheckbox2">No</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-4 d-flex justify-content-between">
                        <label class="title">Do you have an inhouse designer </label>
                        <div >
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                            <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                          </div>
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                            <label class="form-check-label" for="inlineCheckbox2">No</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-4 d-flex justify-content-between">
                        <label class="title">Do you have social channels. If yes, paste the link </label>
                        <div class="mb-3">
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                            <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                          </div>
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                            <label class="form-check-label" for="inlineCheckbox2">No</label>
                          </div>
                        </div>

                      </div>
                      <div class="d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                        <label class="title">Social link</label>
                        <input type="text" class="form-control" placeholder="" aria-label="Brand Full Name">
                      </div>


-->
                  </tab-content>
                  <tab-content title="">
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">Is there anything our onboarding team should be aware of?</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">When is your team available for a weekly update? (DAY of the WEEK, hour)</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">When would you like to launch your affiliate network?</label>
                      <input type="text" class="form-control" placeholder="dd/mm/yyyy" aria-label="Company name">
                    </div>
                    <div class="mb-4 d-flex justify-content-between flex-column flex-sm-column flex-md-row input-adjust align-items-center">
                      <label class="title">Please provide us with your main developer (in charge of the affiliate program setup) email address.</label>
                      <input type="text" class="form-control" placeholder="" aria-label="Company name">
                    </div>
                    <!--
                      <div class="mb-4 d-flex justify-content-between">
                        <label class="title">Private cash game tables?</label>
                        <div >
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                            <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                          </div>
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                            <label class="form-check-label" for="inlineCheckbox2">No</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-4 d-flex justify-content-between">
                        <label class="title">Private tournaments?</label>
                        <div >
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                            <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                          </div>
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                            <label class="form-check-label" for="inlineCheckbox2">No</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-4 d-flex justify-content-between">
                        <label class="title">Do you wish to have play money tables?</label>
                        <div >
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                            <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                          </div>
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                            <label class="form-check-label" for="inlineCheckbox2">No</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-4 d-flex justify-content-between align-items-center">
                        <label class="title text-left">Do you want any additional exclusive games?<br> (Chinese poker, Rummy, Rapid,<br> Pineapple Holdem…)</label>
                        <div >
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                            <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                          </div>
                          <div class="form-check ">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                            <label class="form-check-label" for="inlineCheckbox2">No</label>
                          </div>
                        </div>
                      </div>

-->
                  </tab-content>

                </form-wizard>
              </form>
            </div>
        </div>
    </div>
</div>

</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}

</script>

